import { Link, graphql } from "gatsby";
import React from 'react';
import styles from "../../../components/innerPage.module.css"
import Footer from '../../../components/NonUkResidents/HCP/footer';
import Header from '../../../components/NonUkResidents/HCP/header';
import {useDispatch} from "react-redux"
import Abpi2018 from "../../../images/diurnal-methodology2018.pdf";
import Abpi2017 from "../../../images/2801diurnal-methodology-2017.pdf";
import Abpi2016 from "../../../images/3diurnal-methodology-2016.pdf";
import Donations2016 from "../../../images/5diurnal-grants-and-donations-2016.pdf";
import D2015 from "../../../images/6diurnal-methodology-2015.pdf";
import ScrollAnimation from 'react-animate-on-scroll';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import {showNonUkResidentsHcpDisclaimerPopup} from "../../../actions/nonUkResidents/hcp"
import {toggleNonUkResidentsHcpDisclaimerPopup} from "../../../controllers/nonUkResidents/hcp/disclaimer"
import {sortByLatestYear, addDisclaimersToCmsLinks, deriveHomePathFromSpace, isNullUndefined, richTextOptions, capitalizeFirstLetter} from "../../../util/index"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultNonUkResidentsHcpDisclaimerPopupConfigs, config } from '../../../util/configs'
import queryString from "query-string"
import SEO from "../../../components/seo"
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"

export const pageQueryTabsForFilesTemplateNonUkHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulTabsForFilesTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
            bannerImage {
                file {
                    url
                }
            }
            spaceId
            slug
            title
            parentMenu {
                tabs_for_files_template {
                  spaceId
                  parentMenu {
                    title
                    slug
                  }
                }
            }

            tabs {
                title
                file {
                  url
                }
                description
                spaceId
            }
            bodyText {
                json
            }
        }
    }
`

const TabsForFilesTemplatePageNonUkHcp = (props) => {
    const dispatch = useDispatch();

    const {bannerImage, slug, title, spaceId, parentMenu, tabs,  bodyText} = props.data.contentfulTabsForFilesTemplate;

    let uniqueYearsForTabs = isNullUndefined(tabs) ? [] : tabs.reduce((acc, tab) => {
        if(!acc.includes(tab.title)){
            acc.push(tab.title);
        }
        return acc;
    }, []).sort(sortByLatestYear); 

    let richText = documentToReactComponents(isNullUndefined(bodyText) ? bodyText : bodyText.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultNonUkResidentsHcpDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showNonUkResidentsHcpDisclaimerPopup(actionConfig));
    }
    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);
    let [defaultActiveTab, setDefaultActiveTab] = React.useState(`${uniqueYearsForTabs[0]}`);


    React.useEffect(() => {
        let {veevaDisclaimer, veevaTab} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }

        if(veevaTab){
            setDefaultActiveTab(veevaTab);
        }
  
    }, []);
    return ( 
        <TemplateWrapper>
             <Header/>
        
        <SEO title={`${capitalizeFirstLetter(title)} | Non-UK Residents | HCP`} />

      <div className={styles.banner} style={{background: `url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
      <div className={styles.overlay}></div>
                  <div className="container">
                     <div className="row">
                         <div className="col-12 col-sm-12 col-md-12">
                           <h1 className="">{title}</h1>
                         <ol className="custom_breadcrumb">
                         <li className="breadcrumb-item"><Link to={'/NonUkResidents/HCP/home'}>Home</Link></li>
                         {!isNullUndefined(parentMenu) && !isNullUndefined(parentMenu.tabs_for_files_template) && <li className="breadcrumb-item"><a href="#">
                                      {parentMenu.tabs_for_files_template[0].parentMenu.title}    
                                  </a></li>}
                              <li className="breadcrumb-item active" aria-current="page">
                                  {title}
                              </li>
                          </ol>
                         </div>
                         </div>
                  </div>
      </div>
      
      <div className={styles.content}>
              <div className="container pt-5 pb-5">
              <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
              <ScrollAnimation animateIn="fadeIn">
              {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}

<Tabs defaultTab={defaultActiveTab} onChange={(tabId) => { }}>
<TabList>
  {uniqueYearsForTabs.map(year => {
      return (
          <Tab tabFor={year} key={year}>
              {year}
          </Tab>
      )
  })}
</TabList>
<div className="tabs_content">

  {(() => {
      let tabsContent = [];

      for(let year of uniqueYearsForTabs){
          for(let tab of tabs){
              if(tab.title === year){
                  tabsContent.push(tab);
              }
          }
      }

      return uniqueYearsForTabs.map(year => {
          return (
              <TabPanel key={year} tabId={year}>
                  {tabsContent.filter(item => item.title === year).map((document, index) => {
                      return (
                          <a key={index} href={document.file.url} target="blank"><span className="badge clickable badge-secondary">
                              {document.description}
                          </span></a>
                      )
                  })}
              </TabPanel>
          )
      })

  })()}

</div>
</Tabs>

</ScrollAnimation>
                   </div>
           

          </div>
        

              </div>
        
     </div>
   
      <Footer/>
        </TemplateWrapper>
     );
}

export default TabsForFilesTemplatePageNonUkHcp